import {
  showCookieSetting,
  acceptAllCookies,
  acceptCookies,
  consentCookies,
  checkCookieExist,
  rejectAllRequiredCookies,
} from "./actionTypes";
import {
  CookieAction,
  CookieState,
  setCookie,
  getCookie,
} from "@hooks/useCookie";
import { cookieNames } from "@constants/cookieList";
import { activateGA } from "@utils/ga";

export function cookieReducer(state: CookieState, action: CookieAction) {
  switch (action.type) {
    case showCookieSetting:
      return { ...state, showCookieSettings: !state.showCookieSettings };
    case acceptCookies:
      for (const [key, value] of Object.entries(state.cookieConsent)) {
        setCookie(key, value);
      }
      activateGA();
      return { ...state, visible: false };
    case acceptAllCookies:
      state.cookieNames.forEach((cookieName) => setCookie(cookieName, true));
      activateGA();
      return { ...state, visible: false };
    case rejectAllRequiredCookies:
      state.cookieNames.forEach((cookieName) => setCookie(cookieName, false));
      return { ...state, visible: false };
    case consentCookies:
      var updatedCookieConsent = state;
      const { cookies, event } = action.payload || {};
      cookies?.forEach((cookie) => {
        updatedCookieConsent = {
          ...updatedCookieConsent,
          cookieConsent: {
            ...updatedCookieConsent.cookieConsent,
            [cookie]: (<HTMLInputElement>event?.target).checked ? true : false,
          },
        };
      });
      return updatedCookieConsent;
    case checkCookieExist:
      let cookiesExist = 0;
      state.cookieNames.forEach((cookieName) => {
        if (getCookie(cookieName) !== undefined) {
          cookiesExist++;
        }
      });
      return {
        ...state,
        visible: !(cookiesExist === cookieNames.length) ? true : false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
