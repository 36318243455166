import { ChangeEvent, Reducer, useEffect, useReducer } from "react";
import { cookieReducer } from "@reducer/cookieReducer/cookieReducer";
import Cookies from "js-cookie";
import {
  showCookieSetting,
  acceptAllCookies,
  acceptCookies,
  consentCookies,
  checkCookieExist,
  rejectAllRequiredCookies,
} from "@reducer/cookieReducer/actionTypes";
import {
  cookieNames,
  cookieNameObj,
  CookieNameObj,
} from "@constants/cookieList";

export interface CookieState {
  cookieNames: string[];
  cookieConsent: CookieNameObj;
  visible: boolean;
  showCookieSettings: boolean;
}

export interface CookieAction {
  type:
    | typeof showCookieSetting
    | typeof acceptAllCookies
    | typeof acceptCookies
    | typeof consentCookies
    | typeof checkCookieExist;
  payload?: {
    cookies: string[];
    event: ChangeEvent<Element>;
  };
}

export const setCookie = (cookieName: string, cookieValue: boolean) =>
  Cookies.set(cookieName, new Boolean(cookieValue).toString(), {
    expires: 365,
  });
export const getCookie = (cookieName: string): boolean | undefined => {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue === undefined ? undefined : cookieValue === "true";
};

export const useCookie = () => {
  const initialState: CookieState = {
    cookieNames: cookieNames,
    cookieConsent: cookieNameObj,
    visible: false,
    showCookieSettings: false,
  };

  const [state, dispatch] = useReducer<Reducer<CookieState, CookieAction>>(
    cookieReducer,
    initialState
  );

  const onShowCookieSettings = () => dispatch({ type: showCookieSetting });

  const handleAcceptCookies = () => {
    dispatch({ type: acceptCookies });
  };

  const handleAcceptAllCookies = () => {
    dispatch({ type: acceptAllCookies });
  };

  const handleRejectAllRequiredCookies = () =>
    dispatch({ type: rejectAllRequiredCookies });

  const handleConsentCookie = (cookies: string[]) => (e: ChangeEvent) =>
    dispatch({ type: consentCookies, payload: { cookies, event: e } });

  useEffect(() => {
    dispatch({ type: checkCookieExist });
  }, []);

  return {
    state,
    onShowCookieSettings,
    handleAcceptCookies,
    handleAcceptAllCookies,
    handleRejectAllRequiredCookies,
    handleConsentCookie,
  };
};
