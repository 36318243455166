

export const { showCookieSetting, acceptAllCookies, acceptCookies, consentCookies, checkCookieExist, rejectAllRequiredCookies } = {
    showCookieSetting: "SHOWCOOKIESETTING",
    acceptAllCookies: "ACCEPTALLCOOKIES",
    acceptCookies: "ACCEPTCOOKIES",
    consentCookies: "CONSENTCOOKIES",
    checkCookieExist: "CHECKCOOKIEEXIST",
    rejectAllRequiredCookies: "REJECTALLREQUIREDCOOKIES"
}

