import "@fontsource/raleway/300.css"
import "@fontsource/raleway/700.css"
import React from "react";
import RootElement from "@components/rootElement";

export const wrapRootElement = ({ element }) => (
    <RootElement>
        {element}
    </RootElement>
);

// export const onRouteUpdate = ({ location }) => {
//     if (process.env.NODE_ENV !== 'production') {
//         return null;
//     }
//     const pagePath = location ? location.pathname + location.search + location.hash : undefined;
//     setTimeout(() => {
//         console.log("Called");
//         if (typeof gtag === 'function') {
//             gtag("js", new Date());
//             gtag('event', 'page_view', { page_path: pagePath });
//         }
//     }, 100);
// };
