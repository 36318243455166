import { GA_COOKIE } from "./constants";

export interface Cookie {
  title: string;
  text: string;
  cookieType: "required" | "functional" | "marketing";
  link: string;
  cookies: string[];
}

export interface CookieNameObj {
  [key: string]: boolean;
}

export const cookieTitle = "Cookies mit Liebe serviert!";

export const cookieOverviewText =
  "Mit unseren Cookies möchten wir Ihnen ein fluffiges Einkaufserlebnis mit allem was dazugehört bieten. Dazu zählen zum Beispiel passende Angebote und das Merken von Einstellungen. Wenn das für Sie okay ist, stimmen Sie der Nutzung von Cookies für Präferenzen, Statistiken und Marketing einfach durch einen Klick auf „Akzeptieren“ zu.";

export const cookieList: Cookie[] = [
  {
    title: "Essentielle Cookies",
    text: "Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.",
    cookieType: "required",
    link: "/datenschutzerklaerung",
    cookies: [],
  },
  {
    title: "Funktionell",
    text: "Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.",
    cookieType: "functional",
    link: "/datenschutzerklaerung",
    cookies: [GA_COOKIE],
  },
];

export const cookieNames: string[] = cookieList.reduce(
  (prev: string[], current: Cookie) => [...prev, ...current.cookies],
  []
);

export const cookieNameObj: CookieNameObj = cookieNames.reduce(
  (prev: CookieNameObj, current: string) => {
    return { ...prev, [current]: false };
  },
  {}
);
