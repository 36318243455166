import React, { createContext, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";

const data = graphql`
  query SiteMetadataQuery{
  site {
      siteMetadata {
          title
          description
      }
  }
}
`

export const useSiteMetaDataQuery = () => useContext(SiteMetaDataContext);

const SiteMetaDataContext = createContext();

export const SiteMetaDataContextProvider = ({ children }) => {

  const { site: { siteMetadata } } = useStaticQuery(data);

  return (
    <SiteMetaDataContext.Provider value={siteMetadata}>
      {children}
    </SiteMetaDataContext.Provider>

  )
}