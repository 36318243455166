import { GA_COOKIE } from "@constants/constants";
import { getCookie } from "@hooks/useCookie";
import { linkResolver } from "@prismicHelper/linkResolver";
import { PrismicProvider } from "@prismicio/react";
import { Link } from "gatsby";
import React from "react";
import { FC } from "react";
import { PageContextProvider } from "@query/markdownQuery/usePageQuery";
import { SiteMetaDataContextProvider } from "@query/siteMetaDataQuery/useSiteMetaDataQuery";
import Ga from "./Ga";

interface Props {
  children: JSX.Element;
}

const RootElement: FC<Props> = ({ children }: Props) => {
  return (
    <>
      {getCookie(GA_COOKIE) && <Ga />}
      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <SiteMetaDataContextProvider>
          <PageContextProvider>{children}</PageContextProvider>
        </SiteMetaDataContextProvider>
      </PrismicProvider>
    </>
  );
};

export default RootElement;
