import React from "react";
import { Script } from "gatsby";
import { GATSBY_GA_TRACKING } from "@constants/constants";
import { gaJsScript } from "@utils/ga";

const Ga = () => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GATSBY_GA_TRACKING}`}
        // strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="google-analytics-1"
        // strategy="off-main-thread"
      >
        {gaJsScript}
      </Script>
    </>
  );
};

export default Ga;
