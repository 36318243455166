import React, { createContext, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";


const data = graphql`
query DynamicPagesQuery {
  allPrismicRichtextPage {
    nodes {
      url
      data {
        title {
          text
        }
      }
    }
  }
} 
`
export const usePageQuery = () => useContext(PageContext);

const PageContext = createContext([]);

const PageContextProvider = ({ children }) => {

  const { allPrismicRichtextPage } = useStaticQuery(data);

  return (
    <PageContext.Provider value={{
      allPageInfo: allPrismicRichtextPage.nodes.map(({ url, data: { title } }) => { return { title: title.text, url } }) || []
    }}>
      {children}
    </PageContext.Provider>
  )
};

export { PageContextProvider };
