import { GATSBY_GA_TRACKING, GA_COOKIE } from "@constants/constants";
import { getCookie } from "@hooks/useCookie";

export const activateGA = () => {
  if (getCookie(GA_COOKIE)) {
    const gaScript = document.createElement("script");
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GATSBY_GA_TRACKING}`;
    const script = document.createElement("script");
    script.id = "google-analytics";
    script.innerHTML = gaJsScript;
    document.body.prepend(script);
    document.body.prepend(gaScript);
  }
};

export const gaJsScript = `
    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag(){ window.dataLayer.push(arguments);}

    gtag("js", new Date());
    gtag("config", ${process.env.GA_TRACKING}, {'anonymize_ip': true})
`;
