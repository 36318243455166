exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intrinsic-value-calculator-tsx": () => import("./../../../src/pages/intrinsic-value-calculator.tsx" /* webpackChunkName: "component---src-pages-intrinsic-value-calculator-tsx" */),
  "component---src-pages-ueberuns-tsx": () => import("./../../../src/pages/ueberuns.tsx" /* webpackChunkName: "component---src-pages-ueberuns-tsx" */),
  "component---src-pages-value-investing-lernen-tsx": () => import("./../../../src/pages/value-investing-lernen.tsx" /* webpackChunkName: "component---src-pages-value-investing-lernen-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/SimplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */),
  "component---src-templates-value-investing-page-tsx": () => import("./../../../src/templates/ValueInvestingPage.tsx" /* webpackChunkName: "component---src-templates-value-investing-page-tsx" */)
}

