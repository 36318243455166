
exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "value_investing_page") {

    if (doc.data.topic_category === "value-investing-concept") {
      return `/value-investing/${doc.uid}`;
    }
    if (doc.data.topic_category === "kpi") {
      return `/kennzahlen/${doc.uid}`;
    }
  } else {
    return `/${doc.uid}`
  }
};
